import Container from "../../components/MainContainer/Container";
import { StyledFormInfo } from "../Common.styles";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "../../components/RegistrationWizard/PropTypes";
import PmwCredentialsForm from "../../components/Forms/PmwCredentialsForm/PmwCredentialsForm";
import {
  BlueButton,
  WhiteButton,
} from "../../components/Buttons/GenericButtons";
import { verifyCredentials } from "../../services/api";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { useFormikErrorFields } from "../../components/Hooks/useFormikErrorFields";
import { useNavigate } from "react-router-dom";
import { setFormValues } from "../../components/Utils/InMemoryDataStore/InMemoryDataStore";
import { useTranslation } from "react-i18next";
import { EMAIL_PAGE_ID } from "Pages/Email/Email";
import { FORGOT_CREDENTIALS_MODAL_ID } from "../../components/Modals/ForgotCredentials/Modal";
import { PMW_USER_PAGE_ID } from "Pages/PMWUser/PMWUser";
import { PRE_EXISTING_USER_WITHOUT_EMAIL_MODAL_ID } from "components/Modals/PreExistingUserWithoutEmail/Modal";

export const PMW_CREDENTIALS_PAGE_ID = "pmwCredentials";

const PmwCredentials = ({ control, formFields }) => {
  const { t } = useTranslation();
  const { validateForm, setTouched, setFieldValue, values } =
    useFormikContext();
  const navigate = useNavigate();

  const handleForgotAccountDetails = () => {
    control.showModal(FORGOT_CREDENTIALS_MODAL_ID);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const doVerifyCredentials = async () => {
    const errs = await validateForm();
    control.setLoading(true);
    if (isEmpty(errs)) {
      let {
        authenticated,
        patientKeyInUse,
        preferredEmail,
        isRateLimited,
        isError,
      } = await verifyCredentials({
        username: values.pmwUsername,
        password: values.pmwPassword,
      });
      control.setLoading(false);
      if (isError) {
        control.setAlert("form.errors.general");
      } else if (isRateLimited) {
        control.setAlert("form.errors.rateLimitExceeded");
      } else if (patientKeyInUse) {
        control.showModal(PRE_EXISTING_USER_WITHOUT_EMAIL_MODAL_ID);
      } else if (authenticated) {
        setFieldValue("pmwEmail", preferredEmail);
        setFormValues({ ...values, pmwEmail: preferredEmail });
        if (preferredEmail) {
          control.goToStep(PMW_USER_PAGE_ID);
        } else {
          control.goToStep(EMAIL_PAGE_ID);
        }
      } else {
        control.setAlert("pmwUser.credentialsValidation.invalidCredentials");
      }
    } else {
      useFormikErrorFields(errs, setTouched);
    }
  };

  return (
    <Container subtitle={t("pmwUser.subtitle")} control={control}>
      <StyledFormInfo>
        {t("pmwUser.credentialsValidation.title")}
      </StyledFormInfo>
      <PmwCredentialsForm formFields={formFields} control={control} />
      <WhiteButton
        onClick={handleForgotAccountDetails}
        testId={"forgot-details"}
        label={t("pmwUser.credentialsValidation.forgotPassword")}
        ariaLabel={t("pmwUser.credentialsValidation.forgotPassword")}
      />
      <BlueButton
        onClick={doVerifyCredentials}
        testId={"verify-credentials"}
        label={t("pmwUser.credentialsValidation.continue")}
        ariaLabel={t("pmwUser.credentialsValidation.continue")}
      />
      <WhiteButton
        onClick={handleGoBack}
        testId={"go-back"}
        label={t("pmwUser.credentialsValidation.goBack")}
        ariaLabel={t("pmwUser.credentialsValidation.goBack")}
      />
    </Container>
  );
};

PmwCredentials.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};

export default PmwCredentials;
