import { getConfig } from "AppConfig";
import { getIsPortalEnabled } from "./InMemoryDataStore/InMemoryDataStore";

// Gets the platform via user-agent sniffing, only concerns itself with iOS and Android
// User agent strings, all others return "unsupported"
const getPlatformFromUserAgent = () => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(navigator.userAgent)) {
    return "unsupported";
  }

  if (/android/i.test(navigator.userAgent)) {
    return "android";
  }
  if (
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    (/Mac/.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 0)
  ) {
    return "ios";
  }

  return "unsupported";
};

// Determines if the current platform supports login, useful for disabling buttons or manipulating behaviour
export const getPlatformIsMobile = () => {
  const platform = getPlatformFromUserAgent();
  if (platform === "android" || platform === "ios") return true;
  return false;
};

// Retrieves the link to login to the mobile app (or null if the user is not on iOS/Android)
export const getMobileAppLoginLink = async (language) => {
  const {
    auth0Domain,
    mobileClientId,
    androidRedirectUri,
    iosRedirectUri,
    appPmaApiAudience,
  } = await getConfig();
  const platform = getPlatformFromUserAgent();
  if (platform === "android") {
    return `https://${auth0Domain}/authorize?response_type=token&client_id=${mobileClientId}&redirect_uri=${androidRedirectUri}&language=${language}&audience=${appPmaApiAudience}&prompt=login`;
  }
  if (platform === "ios") {
    return `https://${auth0Domain}/authorize?response_type=token&client_id=${mobileClientId}&redirect_uri=${iosRedirectUri}&language=${language}&audience=${appPmaApiAudience}&prompt=login`;
  }
  return null;
};

// Retrieves the link to login to the portal (or null if the portal URL is not set)
export const getPortalUrl = async () => {
  const { portalUrl } = await getConfig();
  return portalUrl;
};

export const redirectToLogin = async (language) => {
  const isMobile = getPlatformIsMobile();
  const { isPortalSupported } = await getConfig();
  const isPortal = getIsPortalEnabled() && isPortalSupported;
  let redirectUrl;
  if (isPortal) {
    redirectUrl = await getPortalUrl(language);
  } else if (isMobile) {
    redirectUrl = await getMobileAppLoginLink(language);
  } else {
    throw new Error("Unsupported login configuration");
  }
  if (redirectUrl) {
    window.location.href = redirectUrl;
  }
};
