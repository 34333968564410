import { useTranslation } from "react-i18next";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "components/RegistrationWizard/PropTypes";
import RadioGroup from "components/Fields/RadioGroup";
import { useFormikContext } from "formik";
import {
  StyledRadioGroupForm,
  StyledRadioGroupLabel,
} from "Pages/Common.styles";
import { useLaunchDate } from "components/Hooks/useLaunchDate";

const IsPmwUserForm = ({ formFields }) => {
  const { t } = useTranslation();
  const { isPmwUser } = formFields;
  const { values } = useFormikContext();
  const date = useLaunchDate();

  return (
    <StyledRadioGroupForm>
      <StyledRadioGroupLabel data-testid="birth-date-label">
        {t("pmwUser.screeningQuestion", { date: date })}
      </StyledRadioGroupLabel>
      <RadioGroup
        options={[
          { label: t("pmwUser.answers.yes"), value: "true" },
          { label: t("pmwUser.answers.no"), value: "false" },
        ]}
        fieldName={isPmwUser.name}
        defaultSelection={values.isPmwUser}
      />
    </StyledRadioGroupForm>
  );
};

export default IsPmwUserForm;

IsPmwUserForm.propTypes = {
  formFields: FormFieldPropTypes,
  control: ControlPropTypes,
};
