import React from "react";
import PropTypes from "prop-types";
import { ColorPalette, Dialog } from "empire-ui";
import { StyledBody, StyledDialogFooter } from "../Modal.styles";
import { useTranslation } from "react-i18next";

import { BlueButton } from "components/Buttons/GenericButtons";
import { ModalBody, ModalTitle, StyledHR } from "./Modal.styles";

export const TERMS_AND_CONDITIONS_MODAL_ID = "terms-and-conditions";
export const PRIVACY_POLICY_MODAL_ID = "privacy-policy";

export const TermsAndConditionsModal = ({ open, setOpen, title, body }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="terms-and-conditions-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="328px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <ModalBody>
        <ModalTitle id="modal-title">{title}</ModalTitle>
        <StyledBody
          id="modal-description"
          style={{
            lineHeight: "150%",
            color: ColorPalette.grey[0],
            width: "90%",
          }}
        >
          {body}
        </StyledBody>
      </ModalBody>
      <StyledHR />

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          ariaLabel={t("buttons.close.ariaLabel")}
          onClick={() => {
            setOpen(false);
          }}
          label={t("buttons.close.label")}
          testId="close-terms-and-conditions-modal-button"
        ></BlueButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

TermsAndConditionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  body: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export const PrivacyModal = TermsAndConditionsModal;

PrivacyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  body: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};
