import * as Yup from "yup";
import isEmail from "validator/lib/isEmail";

import registrationFormModel from "components/Forms/model";
import { EMAIL_PAGE_ID } from "Pages/Email/Email";
import { BENEFIT_CARD_PAGE_ID } from "Pages/BenefitCard/BenefitCard";
import { BIRTH_DATE_PAGE_ID } from "Pages/BirthDate/BirthDate";
import { TERMS_AND_CONDITIONS_PAGE_ID } from "Pages/TermsAndConditions/TermsAndConditions";
import { IS_PMW_USER_PAGE_ID } from "Pages/PMWUser/isPMWUser";
import { PMW_CREDENTIALS_PAGE_ID } from "Pages/PmwCredentials/PmwCredentials";
import { MEMBER_SUMMARY_PAGE_ID } from "Pages/MemberSummary/MemberSummary";
import { PMW_USER_PAGE_ID } from "Pages/PMWUser/PMWUser";

const {
  formFields: {
    policyNumber,
    divisionNumber,
    certNumber,
    birthDate,
    pmwUsername,
    pmwPassword,
    email,
    confirmEmail,
    isPmwUser,
    memberSummaryEmail,
    pmwUserBirthDate,
    useExistingPMWEmail,
  },
} = registrationFormModel;
const maxAuth0EmailLength = 320;

const emailSchema = Yup.string()
  .required(email.requiredErrKey)
  .max(maxAuth0EmailLength, email.lengthErrKey)
  .test("email-format", email.formatErrKey, (value) => {
    if (value) return isEmail(value);
    return false;
  });

const registrationSchema = Yup.object().shape({
  [isPmwUser.name]: Yup.string().required(isPmwUser.requiredErrKey),
  [divisionNumber.name]: Yup.string().when("isPmwUser", {
    is: "false",
    then: Yup.string()
      .required(divisionNumber.requiredErrKey)
      .length(3, divisionNumber.lengthErrKey)
      .matches(divisionNumber.formatRegex, divisionNumber.formatErrKey),
  }),
  [policyNumber.name]: Yup.string().when("isPmwUser", {
    is: "false",
    then: Yup.string()
      .required(policyNumber.requiredErrKey)
      .length(5, policyNumber.lengthErrKey)
      .matches(policyNumber.formatRegex, policyNumber.formatErrKey),
  }),
  [certNumber.name]: Yup.string().when("isPmwUser", {
    is: "false",
    then: Yup.string()
      .required(certNumber.requiredErrKey)
      .max(9, certNumber.lengthErrKey)
      .matches(certNumber.formatRegex, certNumber.formatErrKey),
  }),
  [birthDate.name]: Yup.string().when("isPmwUser", {
    is: "false",
    then: Yup.string().required(birthDate.requiredErrKey),
  }),
  [email.name]: emailSchema,
  [confirmEmail.name]: emailSchema,
  [pmwPassword.name]: Yup.string().when("isPmwUser", {
    is: "true",
    then: Yup.string().required(pmwPassword.requiredErrKey),
  }),
  [pmwUsername.name]: Yup.string().when("isPmwUser", {
    is: "true",
    then: Yup.string().required(pmwUsername.requiredErrKey),
  }),
});

const validationSchema = {
  BENEFIT_CARD_PAGE_ID: Yup.object().shape({
    [policyNumber.name]: Yup.string()
      .required(policyNumber.requiredErrKey)
      .length(5, policyNumber.lengthErrKey)
      .matches(policyNumber.formatRegex, policyNumber.formatErrKey),
    [divisionNumber.name]: Yup.string()
      .required(divisionNumber.requiredErrKey)
      .length(3, divisionNumber.lengthErrKey)
      .matches(divisionNumber.formatRegex, divisionNumber.formatErrKey),
    [certNumber.name]: Yup.string()
      .required(certNumber.requiredErrKey)
      .max(9, certNumber.lengthErrKey)
      .matches(certNumber.formatRegex, certNumber.formatErrKey),
  }),
  BIRTH_DATE_PAGE_ID: Yup.object().shape({
    [birthDate.name]: Yup.string().required(birthDate.requiredErrKey),
  }),
  PMW_CREDENTIALS_PAGE_ID: Yup.object().shape({
    [pmwUsername.name]: Yup.string().required(pmwUsername.requiredErrKey),
    [pmwPassword.name]: Yup.string().required(pmwPassword.requiredErrKey),
  }),
  IS_PMW_USER_PAGE_ID: Yup.object().shape({
    [isPmwUser.name]: Yup.string().required(isPmwUser.requiredErrKey),
  }),
  MEMBER_SUMMARY_EMAIL_PAGE_ID: Yup.object().shape({
    [memberSummaryEmail.name]: emailSchema,
    [pmwUserBirthDate.name]: Yup.string().required(
      pmwUserBirthDate.requiredErrKey
    ),
  }),
  PMW_USER_PAGE_ID: Yup.object().shape({
    [useExistingPMWEmail.name]: Yup.bool().required(isPmwUser.requiredErrKey),
    [email.name]: emailSchema,
    [confirmEmail.name]: emailSchema,
  }),
  EMAIL_PAGE_ID: registrationSchema,
  TERMS_AND_CONDITIONS_PAGE_ID: registrationSchema,
};

export default {
  [PMW_CREDENTIALS_PAGE_ID]: validationSchema.PMW_CREDENTIALS_PAGE_ID,
  [MEMBER_SUMMARY_PAGE_ID]: validationSchema.MEMBER_SUMMARY_EMAIL_PAGE_ID,
  [BENEFIT_CARD_PAGE_ID]: validationSchema.BENEFIT_CARD_PAGE_ID,
  [BIRTH_DATE_PAGE_ID]: validationSchema.BENEFIT_CARD_PAGE_ID.concat(
    validationSchema.BIRTH_DATE_PAGE_ID
  ),
  [EMAIL_PAGE_ID]: validationSchema.EMAIL_PAGE_ID,
  [TERMS_AND_CONDITIONS_PAGE_ID]: validationSchema.TERMS_AND_CONDITIONS_PAGE_ID,
  [IS_PMW_USER_PAGE_ID]: validationSchema.IS_PMW_USER_PAGE_ID,
  [PMW_USER_PAGE_ID]: validationSchema.PMW_USER_PAGE_ID,
};
