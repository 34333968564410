import React from "react";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { DateInput } from "empire-ui";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";

const CustomDateInput = (props) => {
  const { t, i18n } = useTranslation();
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const errorText = meta.error && meta.touched ? t(meta.error) : "";
  const splitDate = field.value ? field.value.split("-") : [];
  const initialDate = {
    day: splitDate[2] || "",
    month: splitDate[1] || "",
    year: splitDate[0] || "",
  };

  // Custom callback to handle converting the date and setting field value on update
  const setDate = React.useCallback(
    (newDate) => {
      if (newDate.day && newDate.month && newDate.year) {
        const newValue = `${newDate.year}-${newDate.month.padStart(
          2,
          "0"
        )}-${newDate.day.padStart(2, "0")}`;
        setFieldValue(field.name, newValue);
      } else {
        setFieldValue(field.name, "");
      }
    },
    [setFieldValue]
  );

  return (
    <DateInput
      error={errorText}
      trimmed={errorText ? undefined : true} // Use trimmed mode until there is an error to show
      touched={true} // Ensure fields are always touched so we can show errors
      getDate={setDate}
      date={initialDate}
      locale={i18n.language}
      disabled={props.control?.isLoading}
      {...props}
      {...field}
    />
  );
};

CustomDateInput.propTypes = {
  control: ControlPropTypes,
};

export default CustomDateInput;
