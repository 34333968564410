import { StyledTitle, Styledh4 } from "./TextComponents.styles";
import PropTypes from "prop-types";

export const Title = ({ title }) => {
  return (
    <StyledTitle data-testid="welcome-to-group-benefits-h1-title">
      {title}
    </StyledTitle>
  );
};

export const Subtitle = ({ subtitle }) => {
  return (
    <Styledh4 data-testid="sign-up-for-an-account-h6-title">
      {subtitle}
    </Styledh4>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};
Subtitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
};
