import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import {
  ConsentCheckboxContainer,
  HyperLinkContainer,
  LaunchButton,
  StyledCheckBox,
  StyledDeclarationBody,
  StyledDeclarationTitle,
  StyledHyperLink,
  TextContainer,
} from "./TermsAndConditions.styles";
import { useEffect, useState } from "react";
import { ColorPalette, LaunchIcon } from "empire-ui";
import {
  PRIVACY_POLICY_MODAL_ID,
  TERMS_AND_CONDITIONS_MODAL_ID,
} from "components/Modals/TermsAndConditions/Modal";
import { THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID } from "Pages/ThankYouScreen/ExistingUser";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { THANK_YOU_SCREEN_NEW_USER_PAGE_ID } from "Pages/ThankYouScreen/NewUser";
import { registerUser } from "services/api";
import { useFormikErrorFields } from "components/Hooks/useFormikErrorFields";
import { isEmpty } from "lodash";
import { INCORRECT_EMAIL_MODAL_ID } from "components/Modals/IncorrectEmail/Modal";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useIsPortalContext } from "components/Hooks/useIsPortalContext";

export const TERMS_AND_CONDITIONS_PAGE_ID = "termsAndConditions";

export const TermsAndConditionsView = ({ control, isValidNavigation }) => {
  const { t } = useTranslation(),
    navigate = useNavigate(),
    { isAuthenticated, isLoading, user } = useAuth0(),
    { validateForm, setTouched, values } = useFormikContext();
  const [isTermsAccepted, setTermsAccepted] = useState(false);
  const [caslConsent, setCaslConsent] = useState(false);
  const showTermsModal = () => control.showModal(TERMS_AND_CONDITIONS_MODAL_ID);
  const showPrivacyModal = () => control.showModal(PRIVACY_POLICY_MODAL_ID);
  const isPortal = useIsPortalContext();

  useEffect(() => {
    if (isValidNavigation) {
      if (isLoading) {
        control.setLoading(true);
      } else {
        control.setLoading(false);
        if (
          !control.isNewAccount &&
          (!isAuthenticated ||
            (isAuthenticated &&
              values.email !== "" &&
              user.email.toLowerCase() !== values.email.toLowerCase()))
        ) {
          control.showModal(INCORRECT_EMAIL_MODAL_ID);
        }
      }
    }
  }, [isLoading, isAuthenticated, user]);

  const completeUserRegistration = async () => {
    control.setLoading(true);
    const { isRegistered, isRateLimited, isIneligible } = await registerUser(
      values,
      caslConsent,
      isPortal
    );
    control.setLoading(false);

    if (isRegistered) {
      if (control.isNewAccount) {
        control.goToStep(THANK_YOU_SCREEN_NEW_USER_PAGE_ID);
      } else {
        control.goToStep(THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID);
      }
    } else {
      if (isRateLimited) {
        control.setAlert("form.errors.rateLimitExceeded");
      } else if (isIneligible) {
        control.setAlert("form.errors.inEligibleStatus");
      } else {
        control.setAlert("form.errors.registrationFailure");
      }
    }
  };

  const handleContinueButtonClick = async () => {
    const errs = await validateForm();
    if (isEmpty(errs)) {
      if (control.isNewAccount) {
        await completeUserRegistration();
      } else {
        if (
          !isAuthenticated ||
          (isAuthenticated &&
            user.email.toLowerCase() !== values.email.toLowerCase())
        ) {
          control.showModal(INCORRECT_EMAIL_MODAL_ID);
        } else {
          await completeUserRegistration();
        }
      }
    } else {
      /* This should not happen normally if user followed a normal navigation
       and hence showing a generic error message */
      control.setAlert("form.errors.general");
      useFormikErrorFields(errs, setTouched);
    }
  };

  return (
    <Container control={control}>
      <TextContainer>
        <StyledDeclarationTitle>
          {t("termsAndConditionsPage.declaration.title")}
        </StyledDeclarationTitle>
        <StyledDeclarationBody>
          {t("termsAndConditionsPage.declaration.body.firstLine")}
        </StyledDeclarationBody>
        <StyledDeclarationBody>
          {t("termsAndConditionsPage.declaration.body.secondLine")}
        </StyledDeclarationBody>
        <StyledDeclarationBody>
          {t("termsAndConditionsPage.declaration.body.thirdLine")}
        </StyledDeclarationBody>
      </TextContainer>

      <HyperLinkContainer>
        <LaunchButton
          height="0px"
          width="0px"
          onClick={showTermsModal}
          aria-label="launch-terms-and-conditions"
          data-testid="terms-and-conditions-launch-button"
        >
          <LaunchIcon
            width="27px"
            height="27px"
            fill={ColorPalette.secondaryDarkBlue[0]}
          ></LaunchIcon>
        </LaunchButton>
        <StyledHyperLink
          onClick={showTermsModal}
          aria-label="link-to-terms-and-conditions"
          data-testid="terms-and-conditions-launch-link"
        >
          {t("termsAndConditionsPage.modalLabel.termsAndConditions")}
        </StyledHyperLink>
      </HyperLinkContainer>

      <HyperLinkContainer>
        <LaunchButton
          height="0px"
          width="0px"
          onClick={showPrivacyModal}
          aria-label="launch-privacy-policy"
          data-testid="privacy-policy-launch-button"
        >
          <LaunchIcon
            width="27px"
            height="27px"
            fill={ColorPalette.secondaryDarkBlue[0]}
          ></LaunchIcon>
        </LaunchButton>

        <StyledHyperLink
          onClick={showPrivacyModal}
          aria-label="link-to-privacy-policy"
          data-testid="privacy-policy-launch-link"
        >
          {t("termsAndConditionsPage.modalLabel.privacyPolicy")}
        </StyledHyperLink>
      </HyperLinkContainer>

      <ConsentCheckboxContainer>
        <StyledCheckBox
          label={t("termsAndConditionsPage.consent.label")}
          name="termsCheckbox"
          alignTop
          aria-label="agree-to-terms-and-conditions-and-privacy-policy-checkbox"
          data-testid="terms-checkbox"
          value={isTermsAccepted}
          selection={isTermsAccepted}
          onChange={(event) => {
            event.target.checked
              ? setTermsAccepted(true)
              : setTermsAccepted(false);
          }}
        ></StyledCheckBox>

        <StyledCheckBox
          label={t("termsAndConditionsPage.consent.caslConsent")}
          name="consentCheckbox"
          alignTop
          aria-label="agree-to-terms-and-conditions-and-privacy-policy-checkbox"
          data-testid="casl-checkbox"
          value={caslConsent}
          selection={caslConsent}
          onChange={(event) => {
            event.target.checked ? setCaslConsent(true) : setCaslConsent(false);
          }}
        ></StyledCheckBox>
      </ConsentCheckboxContainer>

      <BlueButton
        fullWidth={true}
        disabled={!isTermsAccepted || isLoading}
        label={t("termsAndConditionsPage.registrationButton.label")}
        onClick={handleContinueButtonClick}
        ariaLabel={t("form.continueButton.aria-label")}
        testId="continue-to-next-page-button"
        control={control}
      ></BlueButton>
      <WhiteButton
        style={{ marginTop: "8px" }}
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={() => {
          navigate(-1);
        }}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-to-the-previous-page-button"
        control={control}
      ></WhiteButton>
    </Container>
  );
};

TermsAndConditionsView.propTypes = {
  control: ControlPropTypes,
  isValidNavigation: PropTypes.bool,
};
