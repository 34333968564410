import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { GreyStyledBody, StyledContainerNewUser } from "../Common.styles";
import { Title } from "components/TextComponents/TextComponents";
import { useEffect } from "react";
import { clearFormValues } from "components/Utils/InMemoryDataStore/InMemoryDataStore";

export const THANK_YOU_SCREEN_NEW_USER_PAGE_ID = "thankYouScreenNewUser";

export const ThankYouScreenNewUser = () => {
  const { t } = useTranslation();
  useEffect(() => {
    clearFormValues();
  }, []);

  return (
    <Container hideTitle hideSubtitle hideSignIn>
      <StyledContainerNewUser>
        <Title title={t("thankyouScreen.newUser.title")} />
        <GreyStyledBody>
          {t("thankyouScreen.newUser.body.firstLine")}
        </GreyStyledBody>
      </StyledContainerNewUser>
    </Container>
  );
};
