import { StyledForm, StyledLabel } from "../Form.styles";
import { useTranslation } from "react-i18next";
import TextInput from "components/Fields/TextInput";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "components/RegistrationWizard/PropTypes";
import DateInput from "components/Fields/DateInput";
import {
  StyledBirthDateForm,
  StyledBirthDateLabel,
} from "../BirthDateForm/BirthDateForm.styles";

const MemberSummaryRequestForm = ({ formField, control }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <StyledLabel data-testid="email-id-label">
        {t("form.email.memberSummaryEmailLabel")}
      </StyledLabel>
      <TextInput
        name={formField.memberSummaryEmail.name}
        placeholder={t("form.email.placeholder")}
        aria-label={t("form.email.memberSummaryEmailLabel")}
        data-testid="email-textfield"
      />

      <StyledBirthDateForm>
        <StyledBirthDateLabel data-testid="birth-date-label">
          {t("form.birthDate.label")}
        </StyledBirthDateLabel>
        <DateInput name={formField.pmwUserBirthDate.name} control={control} />
      </StyledBirthDateForm>
    </StyledForm>
  );
};

MemberSummaryRequestForm.propTypes = {
  formField: FormFieldPropTypes,
  control: ControlPropTypes,
};

export default MemberSummaryRequestForm;
