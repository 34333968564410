import styled from "styled-components";
import { ColorPalette } from "empire-ui";

export const StyledAppTitle = styled.h1`
  font-size: 14px;
  font-family: "MuseoSans500Regular", san-serif;
  color: ${ColorPalette.grey[0]};
`;

export const StyledHeader = styled.header`
  display: flex;
  background-color: ${ColorPalette.white};
  color: ${ColorPalette.white};
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 0 16px;
  font-size: 14px;
  align-items: center;
  border-bottom: 2px solid ${ColorPalette.grey[7]};
  min-height: 50px;
  width: 100%;
  top: 0;
  position: fixed;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const LangSeparator = styled.div`
  border-left: 1px solid ${ColorPalette.grey[3]};
  height: 16px;
`;
// Contains overrides for empire-ui Button
export const LangContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  button {
    padding: 0 0 0 4px !important;
    div {
      font-weight: 600;
      color: ${ColorPalette.darkBlue[0]};
    }
  }
`;
