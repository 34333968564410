import { Spinner } from "empire-ui";
import styled from "styled-components";

const SpinnerOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

const LoadingOverlay = () => {
  return (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  );
};

export default LoadingOverlay;
