import { WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PAGE_NOT_FOUND_PAGE_ID = "pagenotfound";

const PageNotFoundView = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);
  const { t } = useTranslation();

  return (
    <Container title="PAGE NOT FOUND" hideSubtitle>
      <WhiteButton
        style={{ width: "80%" }}
        fullWidth={false}
        label={t("buttons.back.label")}
        onClick={handleClick}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-to-previous-page-button"
      />
    </Container>
  );
};

PageNotFoundView.propTypes = {
  control: ControlPropTypes,
};
export default PageNotFoundView;
