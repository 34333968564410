import { useTranslation } from "react-i18next";
import TextInput from "components/Fields/TextInput";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "components/RegistrationWizard/PropTypes";
import { StyledForm, StyledLabel } from "../Form.styles";

const BenefitCardForm = ({ formFields, control }) => {
  const { t } = useTranslation();
  const { policyNumber, divisionNumber, certNumber } = formFields;

  return (
    <StyledForm>
      <StyledLabel data-testid="policy-number-label">
        {t("form.policyNumber.label", "What is your policy number?")}
      </StyledLabel>
      <TextInput
        name={policyNumber.name}
        placeholder="G1234"
        aria-label={t("form.policyNumber.aria-label")}
        data-testid="policy-number-textfield"
        control={control}
      />

      <StyledLabel data-testid="division-number-label">
        {t("form.divisionNumber.label", "What is your division number?")}
      </StyledLabel>
      <TextInput
        name={divisionNumber.name}
        placeholder="123"
        aria-label={t("form.divisionNumber.aria-label")}
        data-testid="division-number-textfield"
        control={control}
      />

      <StyledLabel data-testid="cert-number-label">
        {t("form.certNumber.label", "What is your certificate number?")}
      </StyledLabel>
      <TextInput
        name={certNumber.name}
        placeholder="123456789"
        aria-label={t("form.certNumber.aria-label")}
        data-testid="cert-number-textfield"
        control={control}
      />
    </StyledForm>
  );
};

BenefitCardForm.propTypes = {
  formFields: FormFieldPropTypes,
  control: ControlPropTypes,
};
export default BenefitCardForm;
