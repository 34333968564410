import {
  StyledBirthDateForm,
  StyledBirthDateLabel,
} from "./BirthDateForm.styles";
import { useTranslation } from "react-i18next";
import DateInput from "components/Fields/DateInput";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "components/RegistrationWizard/PropTypes";

const BirthDateForm = ({ formFields, control }) => {
  const { t } = useTranslation();
  return (
    <StyledBirthDateForm>
      <StyledBirthDateLabel data-testid="birth-date-label">
        {t("form.birthDate.label")}
      </StyledBirthDateLabel>
      <DateInput name={formFields.birthDate.name} control={control} />
    </StyledBirthDateForm>
  );
};

export default BirthDateForm;

BirthDateForm.propTypes = {
  formFields: FormFieldPropTypes,
  control: ControlPropTypes,
};
