import { containerMaxWidth } from "components/MainContainer/Container.styles";
import { Styleda } from "components/TextComponents/TextComponents.styles";
import { Button, Checkbox, ColorPalette, Typography } from "empire-ui";
import styled from "styled-components";

const fontStylingConstants = Typography.Constants;

export const ConsentCheckboxContainer = styled.div`
  width: ${window.innerWidth * 0.9 > containerMaxWidth * 0.9
    ? containerMaxWidth * 0.9
    : window.innerWidth * 0.9}px;
  height: 250px;
  background-color: #eff8fc;
  text-align: left;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  margin-left: ${window.innerWidth * -0.08 < containerMaxWidth * -0.08
    ? containerMaxWidth * -0.08
    : window.innerWidth * -0.08}px;
  margin-bottom: 21px;
  margin-top: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 43.35px;
  row-gap: 20px;
  margin-bottom: 20px;
`;

export const StyledDeclarationTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${ColorPalette.secondaryDarkBlue[0]};
`;

export const StyledDeclarationBody = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${ColorPalette.grey[0]};
`;

export const HyperLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`;

export const StyledHyperLink = styled(Styleda)`
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  margin-left: 13.69px;
  text-align: left;
  cursor: pointer;
`;

export const StyledCheckBox = styled(Checkbox)`
  color: ${ColorPalette.grey[0]};
  font-size: 14px;
  font-family: ${fontStylingConstants.fontFamily(400, "Regular")};
  letter-spacing: ${fontStylingConstants.letterSpacingWide}px;
  width: 90%;
`;

export const LaunchButton = styled(Button)`
  border-width: 0px;
`;
