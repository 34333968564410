import { useField } from "formik";
import { Input } from "empire-ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";

const StyledInput = styled(Input)`
  padding-top: 0;
  padding-bottom: 0;
`;

const TextInput = (props) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? t(meta.error) : null;

  return (
    <StyledInput
      type="text"
      error={errorText}
      trimmed={errorText ? undefined : true} // Use trimmed mode until there is an error to show
      touched={true} // Ensure fields are always touched so we can show errors
      disabled={props.control?.isLoading}
      {...props}
      {...field}
    />
  );
};

TextInput.propTypes = {
  control: ControlPropTypes,
};

export default TextInput;
