import styled from "styled-components";
import { ColorPalette, Typography } from "empire-ui";

const fontStylingConstants = Typography.Constants;

export const StyledTitle = styled.h3`
  color: ${ColorPalette.darkBlue[0]};
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: 31.25px;
  letter-spacing: ${fontStylingConstants.letterSpacingXTight}px;
`;

export const Styledh4 = styled.h4`
  color: ${ColorPalette.grey[0]};
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: 25px;
  letter-spacing: ${fontStylingConstants.letterSpacingXTight}px;
`;

export const Styledp = styled.p`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const Styleda = styled.a`
  color: ${ColorPalette.secondaryDarkBlue[0]};
  text-decoration: underline;
  cursor: pointer;
`;
