import styled from "styled-components";
import { ColorPalette, Typography } from "empire-ui";
import { StyledForm, StyledLabel } from "components/Forms/Form.styles";

const fontStylingConstants = Typography.Constants;

export const StyledBody = styled.p`
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
`;

export const GreyStyledBody = styled(StyledBody)`
  color: #555555;
  margin-top: 18px;
`;

export const BlackStyledBody = styled(StyledBody)`
  color: ${ColorPalette.grey[0]};
  margin-top: 18px;
  margin-bottom: 18px;
`;

const StyledContainer = styled.div`
  margin-top: 24.43px;
`;

export const StyledContainerNewUser = styled(StyledContainer)`
  margin-bottom: 54px;
`;

export const StyledContainerExistingUser = styled(StyledContainer)`
  margin-bottom: 29px;
`;

export const StyledFormInfo = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: ${ColorPalette.grey[0]};
`;

export const StyledRadioGroupForm = styled(StyledForm)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > div > div:nth-child(1) {
    flex: 2;
    padding-right: 10px;
  }
  & > div > div:nth-child(2) {
    flex: 2;
    padding-right: 10px;
  }
  & > div > div:nth-child(3) {
    flex: 4;
  }
`;

export const StyledRadioGroupLabel = styled(StyledLabel)`
  width: 100%;
`;
