import styled from "styled-components";
import { ColorPalette, Typography, DialogBody, DialogFooter } from "empire-ui";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const fontStylingConstants = Typography.Constants;

export const StyledTitle = styled.h1`
  color: ${ColorPalette.grey[0]};
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: 1.125rem;
  letter-spacing: ${fontStylingConstants.letterSpacingXTight}px;
`;

export const StyledBody = styled.p`
  line-height: 100%;
  margin-top: 15px;
  font-size: 0.875rem;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
  color: #555555;
`;

export const DialogueBody = styled(StyledBody)`
  line-height: 1.5rem;
  font-size: 1rem;
`;

export const UnsupportedDeviceModalBody = styled.div`
  line-height: 100%;
  margin-top: 15px;
  letter-spacing: ${fontStylingConstants.letterSpacingTight}px;
  color: #555555;
`;

export const StyledStrongBody = styled(StyledBody)`
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
`;

export const StyledDialogBody = styled(DialogBody)`
  row-gap: 10px;
  overflow-y: hidden;
`;

export const StyledDialogFooter = styled(DialogFooter)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
`;

export const StyledImage = styled.img`
  margin-top: 10px;
  width: 43;
  height: 43;
`;

export const StyledLink = styled(Link)`
  color: ${ColorPalette.secondaryDarkBlue[0]};
  &:visited: \{
    color: inherit;
  \}
`;

export const DisabledLink = styled(Link)`
    color: ${ColorPalette.grey[0]};
    pointer-events: none;
    &:visited: \{
      color: inherit;
    \}
`;

export const ActionLink = ({ disabled = false, ...props }) => {
  return disabled === true ? (
    <div style={{ fontSize: "0.875rem" }}>
      <DisabledLink disabled {...props} />
    </div>
  ) : (
    <div style={{ fontSize: "0.875rem" }}>
      <StyledLink {...props} />
    </div>
  );
};

ActionLink.propTypes = {
  disabled: PropTypes.bool,
  ...(Link.propTypes || {}),
};
