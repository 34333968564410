import styled from "styled-components";
import { ColorPalette, DialogBody, DialogFooter, Typography } from "empire-ui";

const fontStylingConstants = Typography.Constants;

export const ModalTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${ColorPalette.secondaryDarkBlue[0]};
  text-align: left;
`;

export const ModalBody = styled(DialogBody)`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 500px;
  overflow-y: scroll;
`;

export const StyledBody = styled.div`
  font-size: 14px;
  margin-top: 15px;
  align-items: center;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
`;

export const StyledHR = styled.hr`
  width: 100%;
  color: #c9c9c5;
  margin-top: 38px;
`;

export const StyledDialogFooter = styled(DialogFooter)`
  margin-top: 17px;
  margin-bottom: 20px;
`;
