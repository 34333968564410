import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "components/RegistrationWizard/PropTypes";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { sendMemberSummary } from "services/api";
import { useFormikErrorFields } from "components/Hooks/useFormikErrorFields";
import { useNavigate } from "react-router-dom";
import { StyledBody } from "Pages/Common.styles";
import styled from "styled-components";
import { RESEND_MEMBER_SUMMARY_PAGE_ID } from "Pages/ResendMemberSummary/ResendMemberSummary";
import MemberSummaryRequestForm from "components/Forms/MemberSummaryRequestForm/MemberSummaryRequestForm";
import { useIsPortalContext } from "../../components/Hooks/useIsPortalContext";

export const MEMBER_SUMMARY_PAGE_ID = "memberSummaryForm";

const StyledH6 = styled.h6`
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
`;

export const MemberSummaryView = ({ control, formFields }) => {
  const { t } = useTranslation();
  const isPortal = useIsPortalContext();
  const { validateForm, setTouched, values } = useFormikContext(),
    navigate = useNavigate();

  const handleContinueButtonClick = async () => {
    const errs = await validateForm();
    if (isEmpty(errs)) {
      control.setLoading(true);
      const { isValid, isRateLimited } = await sendMemberSummary(
        values.memberSummaryEmail,
        values.pmwUserBirthDate,
        isPortal
      );
      control.setLoading(false);
      if (isValid === true) {
        control.goToStep(RESEND_MEMBER_SUMMARY_PAGE_ID);
      } else if (isValid === false) {
        control.setAlert("memberSummary.invalidCreds");
      } else {
        if (isRateLimited) {
          control.setAlert("form.errors.rateLimitExceeded");
        } else {
          control.setAlert("form.errors.general");
        }
      }
    } else {
      useFormikErrorFields(errs, setTouched);
    }
  };

  return (
    <Container hideSubtitle hideTitle control={control}>
      <StyledH6 data-testid="sample-benefit-card-information-header">
        {t("memberSummary.title")}
      </StyledH6>
      <StyledBody data-testid="sample-benefit-card-information-body">
        {t("memberSummary.body")}
      </StyledBody>
      <MemberSummaryRequestForm formField={formFields} control={control} />
      <BlueButton
        fullWidth={true}
        label={t("form.continueButton.label")}
        onClick={handleContinueButtonClick}
        ariaLabel={t("form.continueButton.aria-label")}
        testId="continue-to-next-page-button"
        control={control}
      />

      <WhiteButton
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={() => navigate(-1)}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-button"
        control={control}
      ></WhiteButton>
    </Container>
  );
};

MemberSummaryView.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};

export default MemberSummaryView;
