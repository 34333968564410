import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    detection: {
      order: [
        "querystring",
        "navigator",
        "cookie",
        "localStorage",
        "sessionStorage",
      ],
    },
    fallbackLng: {
      "fr-CA": ["fr"],
      "en-CA": ["en"],
      default: ["en"],
    },
    lowerCaseLng: true,
    supportedLngs: ["en", "fr"],
    interpolation: {
      escapeValue: false, // disabled because React does this by default
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
    },
  });

export default i18n;
