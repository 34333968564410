import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { GreyStyledBody, StyledContainerExistingUser } from "../Common.styles";
import { Title } from "components/TextComponents/TextComponents";
import { BENEFIT_CARD_PAGE_ID } from "Pages/BenefitCard/BenefitCard";
import { useFormikContext } from "formik";
import { sendMemberSummary } from "services/api";
import { useNavigate } from "react-router-dom";

export const RESEND_MEMBER_SUMMARY_PAGE_ID = "resendMemberSummary";

export const ResendMemberSummary = ({ control }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const nav = useNavigate();

  const handleResendButtonClick = async () => {
    control.setLoading(true);
    const { isValid, isRateLimited } = await sendMemberSummary(
      values.memberSummaryEmail,
      values.pmwUserBirthDate
    );
    control.setLoading(false);
    if (isValid === null) {
      if (isRateLimited) {
        control.setAlert("form.errors.rateLimitExceeded");
      } else {
        control.setAlert("form.errors.general");
      }
    }
  };

  return (
    <Container hideTitle hideSubtitle hideSignIn control={control}>
      <StyledContainerExistingUser>
        <Title title={t("resendMemberSummary.title")} />
        <GreyStyledBody style={{ marginBottom: "18px" }}>
          {t("resendMemberSummary.body")}
        </GreyStyledBody>
        <BlueButton
          fullWidth={true}
          label={t("resendMemberSummary.buttons.resend")}
          onClick={handleResendButtonClick}
          ariaLabel={t("thankyouScreen.continueButton.aria-label")}
          testId="continue-to-login-button"
        ></BlueButton>
        <WhiteButton
          fullWidth={true}
          label={t("resendMemberSummary.buttons.reset")}
          onClick={() => {
            nav({
              pathname: `/${BENEFIT_CARD_PAGE_ID}`,
              search: "?isPmwUser=false",
            });
          }}
          ariaLabel={t("thankyouScreen.continueButton.aria-label")}
          testId="continue-to-login-button"
          style={{ marginTop: "8px" }}
        ></WhiteButton>
      </StyledContainerExistingUser>
    </Container>
  );
};

ResendMemberSummary.propTypes = {
  control: ControlPropTypes,
};
