import axios from "axios";
import { getConfig } from "AppConfig";

let instance = null;

const getInstance = async () => {
  if (instance === null) {
    const { appPmaApiUrl: baseURL } = await getConfig();
    instance = axios.create({ baseURL });
  }
  return instance;
};

const get = async (...args) => {
  const instance = await getInstance();
  return instance.get(...args);
};

const post = async (...args) => {
  const instance = await getInstance();
  return instance.post(...args);
};

const put = async (...args) => {
  const instance = await getInstance();
  return instance.put(...args);
};

const delete_ = async (...args) => {
  const instance = await getInstance();
  return instance.delete(...args);
};

export default { get, post, put, delete: delete_ };
