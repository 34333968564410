import styled from "styled-components";
import { ColorPalette, Alert } from "empire-ui";
import watermark_bg from "../../assets/img/watermark_bg.png";

export const containerMaxWidth = 400;

export const StyledContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 70px;
  padding-bottom: 30px;
  background-color: ${ColorPalette.white[0]};
  width: 90%;
  position: relative;
  max-width: ${containerMaxWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${watermark_bg});
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: left bottom;
  border-radius: 4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
`;

export const StyledAlert = styled(Alert)`
  margin-top: 10px;
`;

export const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const StyledDiv = styled.div`
  width: 82%;
  margin: auto;
  display: flex;
  row-gap: 10px;
  justify-content: space-between;
  flex-direction: column;
`;
