import {
  PRE_EXISTING_USER_MODAL_ID,
  PreExistingUserModal,
} from "../Modals/PreExistingUserWithEmail/Modal";
import {
  INCORRECT_EMAIL_MODAL_ID,
  IncorrectEmailModal,
} from "../Modals/IncorrectEmail/Modal";
import {
  PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID,
  PreExistingAuth0AccountModal,
} from "../Modals/PreExistingAuth0Account/Modal";
import {
  UNSUPPORTED_DEVICE_MODAL,
  UnsupportedDeviceModal,
} from "../Modals/UnsupportedDevice/Modal";
import {
  EMAIL_IN_USE_MODAL_ID,
  EmailInUseModal,
} from "../Modals/EmailInUse/Modal";
import {
  INACTIVITY_TIMEOUT_WARNING_MODAL_ID,
  InactivityTimeoutWarningModal,
} from "../Modals/InactivityTimeoutWarning/Modal";
import {
  INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID,
  InactivityTimeoutConfirmationModal,
} from "../Modals/InactivityTimeoutConfirmation/Modal";
import {
  PRIVACY_POLICY_MODAL_ID,
  PrivacyModal,
  TERMS_AND_CONDITIONS_MODAL_ID,
  TermsAndConditionsModal,
} from "../Modals/TermsAndConditions/Modal";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ControlPropTypes } from "./PropTypes";
import PropTypes from "prop-types";
import ForgotCredentialsModal, {
  FORGOT_CREDENTIALS_MODAL_ID,
} from "../Modals/ForgotCredentials/Modal";
import {
  EMAIL_CONFIRMATION_MODAL_ID,
  EmailConfirmationModal,
} from "components/Modals/EmailConfirmationModal/EmailConfirmationModal";
import {
  ExistingUserWithoutEmail,
  PRE_EXISTING_USER_WITHOUT_EMAIL_MODAL_ID,
} from "components/Modals/PreExistingUserWithoutEmail/Modal";

const RegistrationModals = ({
  activeModal,
  setActiveModal,
  control,
  setIsNewAccount,
}) => {
  const { t, i18n } = useTranslation();
  const privacyPolicyUrl =
    i18n.language === "fr"
      ? "https://www.empire.ca/fr/privacy-policy"
      : "https://www.empire.ca/privacy-policy";
  const privacyPracticeUrl =
    i18n.language === "fr"
      ? "https://www.empire.ca/fr/vos-renseignements-personnels-et-votre-vie-privee"
      : "https://www.empire.ca/your-personal-information-and-your-privacy";
  const legalPageUrl =
    i18n.language === "fr"
      ? "https://www.empire.ca/fr/legal"
      : "https://www.empire.ca/legal";
  const customTranslationComponents = {
    bold: <b style={{ fontFamily: "MuseoSans700Regular" }} />,
    underline: <u style={{ textDecoration: "underline" }} />,
    italics: <i style={{ fontStyle: "italic" }} />,
    break: <br />,
    linebreak: (
      <>
        <br />
        <br />
      </>
    ),
    email: (
      <Link
        to="#"
        onClick={() => (window.location = "mailto:group.csu@empire.ca")}
      />
    ),
    privacyPolicyUrl: <a href={privacyPolicyUrl} />,
    privacyPracticeUrl: <a href={privacyPracticeUrl} />,
    legalPageUrl: <a href={legalPageUrl} />,
  };
  return (
    <>
      <PreExistingUserModal
        open={activeModal === PRE_EXISTING_USER_MODAL_ID}
        setOpen={() => setActiveModal("")}
        control={control}
      />
      <ExistingUserWithoutEmail
        open={activeModal === PRE_EXISTING_USER_WITHOUT_EMAIL_MODAL_ID}
        setOpen={() => setActiveModal("")}
        control={control}
      />
      <IncorrectEmailModal
        open={activeModal === INCORRECT_EMAIL_MODAL_ID}
        setOpen={() => setActiveModal("")}
        control={control}
      />
      <PreExistingAuth0AccountModal
        open={activeModal === PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID}
        setOpen={() => setActiveModal("")}
        setIsNewAccount={setIsNewAccount}
        control={control}
      />
      <UnsupportedDeviceModal
        open={activeModal === UNSUPPORTED_DEVICE_MODAL}
        setOpen={() => setActiveModal("")}
      />
      <EmailInUseModal
        open={activeModal === EMAIL_IN_USE_MODAL_ID}
        setOpen={() => setActiveModal("")}
        control={control}
      />
      <EmailConfirmationModal
        open={activeModal === EMAIL_CONFIRMATION_MODAL_ID}
        setOpen={() => setActiveModal("")}
        control={control}
      />
      <InactivityTimeoutWarningModal
        open={activeModal === INACTIVITY_TIMEOUT_WARNING_MODAL_ID}
        setOpen={() => setActiveModal("")}
      />
      <InactivityTimeoutConfirmationModal
        open={activeModal === INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID}
        setOpen={() => setActiveModal("")}
      />
      <TermsAndConditionsModal
        open={activeModal === TERMS_AND_CONDITIONS_MODAL_ID}
        setOpen={() => setActiveModal("")}
        title={t("termsAndConditionsPage.modalTitle.termsAndConditions")}
        body={
          <Trans
            i18nKey="termsAndConditionsPage.termsAndConditions"
            components={customTranslationComponents}
          />
        }
      />
      <PrivacyModal
        open={activeModal === PRIVACY_POLICY_MODAL_ID}
        setOpen={() => setActiveModal("")}
        title={t("termsAndConditionsPage.modalTitle.privacyPolicy")}
        body={
          <Trans
            i18nKey="termsAndConditionsPage.privacyPolicy"
            components={customTranslationComponents}
          />
        }
      />
      <ForgotCredentialsModal
        open={activeModal === FORGOT_CREDENTIALS_MODAL_ID}
        setOpen={() => setActiveModal("")}
        control={control}
      />
    </>
  );
};

RegistrationModals.propTypes = {
  activeModal: PropTypes.string.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  control: ControlPropTypes.isRequired,
  setIsNewAccount: PropTypes.func.isRequired,
};

export default RegistrationModals;
