import React, { useEffect, useState } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import HeaderBar from "components/Header/Header";
import BasePathRedirect from "components/BasePathRedirect/BasePathRedirect";
import HelpButton from "components/Buttons/HelpButton";
import RegistrationWizard from "components/RegistrationWizard/RegistrationWizard";
import { BrowserRouter } from "react-router-dom";
import { HelpModal } from "components/Modals/HelpModal/Modal";

function App() {
  const { i18n, t } = useTranslation();
  const [displayHelp, setDisplayHelp] = useState(false);

  const toggleLanguage = () => {
    const newLang = i18n.language === "fr" ? "en" : "fr";
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    document.title = t("appTitle");
  });

  return (
    <>
      <BasePathRedirect />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div className="App">
          <div className="App-div">
            <RegistrationWizard />
            <HeaderBar i18n={i18n} t={t} onToggleLanguage={toggleLanguage} />
            <HelpModal open={displayHelp} setOpen={setDisplayHelp} />
            <HelpButton
              onClick={setDisplayHelp}
              style={{ position: "fixed", bottom: "0" }}
            ></HelpButton>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

// Catch possible exception from translations not being loaded yet
function WrappedApp() {
  return (
    <React.Suspense fallback="... is loading">
      <App />
    </React.Suspense>
  );
}

export default WrappedApp;
