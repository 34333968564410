import PropTypes from "prop-types";

export const ControlPropTypes = PropTypes.shape({
  alert: PropTypes.shape({
    step: PropTypes.string,
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  currentStep: PropTypes.string,
  previousStep: PropTypes.string,
  goToStep: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  redirectToAppLogin: PropTypes.func.isRequired,
});

export const FormFieldPropTypes = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
  )
);
