import {
  StyledBlueButton,
  StyledWhiteButton,
} from "components/Buttons/Button.styles";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import PropTypes from "prop-types";

export const BlueButton = ({
  label,
  onClick,
  ariaLabel,
  fullWidth,
  testId,
  style,
  control,
  disabled,
}) => {
  return (
    <StyledBlueButton
      style={style}
      height="50px"
      fullWidth={fullWidth}
      variant="filled"
      color="empireBlue"
      size="fullWidth"
      onClick={onClick}
      aria-label={ariaLabel}
      data-testid={testId}
      disabled={disabled || control?.isLoading}
    >
      <b>{label}</b>
    </StyledBlueButton>
  );
};

export const WhiteButton = ({
  label,
  onClick,
  ariaLabel,
  fullWidth,
  testId,
  style,
  control,
}) => {
  return (
    <StyledWhiteButton
      style={style}
      fullWidth={fullWidth}
      variant="outlined"
      size="fullWidth"
      onClick={onClick}
      aria-label={ariaLabel}
      data-testid={testId}
      height="50px"
      disabled={control?.isLoading}
    >
      <strong>{label}</strong>
    </StyledWhiteButton>
  );
};

BlueButton.propTypes = {
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  control: ControlPropTypes,
};

WhiteButton.propTypes = {
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  control: ControlPropTypes,
};
