import { StyledForm, StyledLabel } from "../Form.styles";
import { useTranslation } from "react-i18next";
import TextInput from "components/Fields/TextInput";
import { FormFieldPropTypes } from "components/RegistrationWizard/PropTypes";
import PropTypes from "prop-types";

const EmailForm = ({
  formField,
  firstLabel = "form.email.label",
  secondLabel = "form.email.confirmEmailLabel",
}) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <StyledLabel data-testid="email-id-label">{t(firstLabel)}</StyledLabel>
      <TextInput
        name={formField.email.name}
        placeholder={t("form.email.placeholder")}
        aria-label={t("form.email.aria-label")}
        data-testid="email-textfield"
      />

      <StyledLabel data-testid="email-id-label">{t(secondLabel)}</StyledLabel>
      <TextInput
        name={formField.confirmEmail.name}
        placeholder={t("form.email.placeholder")}
        aria-label={t("form.email.confirm-email-aria-label")}
        data-testid="email-textfield"
      />
    </StyledForm>
  );
};

EmailForm.propTypes = {
  formField: FormFieldPropTypes,
  firstLabel: PropTypes.string,
  secondLabel: PropTypes.string,
};

export default EmailForm;
