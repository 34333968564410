import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ResponsiveModal from "../ResponsiveModal";

export const UNSUPPORTED_DEVICE_MODAL = "unsupported-device-modal";

export const UnsupportedDeviceModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <ResponsiveModal
      testId="unsupported-device-modal"
      setOpen={setOpen}
      open={open}
      title={t("unsupportedDeviceModal.title")}
      description={t("unsupportedDeviceModal.body")}
      actions={[
        {
          testId: "close-modal-button",
          label: t("buttons.close.label"),
          onClick: () => setOpen(false),
        },
      ]}
    />
  );
};

UnsupportedDeviceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
