import { UNSUPPORTED_DEVICE_MODAL } from "components/Modals/UnsupportedDevice/Modal";
import { useEffect } from "react";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import styled from "styled-components";
import { useIsPlatformSupported } from "../../components/Hooks/useIsPlatformSupported";

export const PASSWORD_RESET_VIEW_PAGE_ID = "passwordResetRedirectView";

export const StyledDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  background-color: #ffffff;
  z-index: 1;
`;

const PasswordResetRedirectView = ({ control }) => {
  const { isSupported, isLoading } = useIsPlatformSupported();

  useEffect(() => {
    if (isSupported) {
      control.redirectToAppLogin();
    } else if (!isLoading) {
      control.showModal(UNSUPPORTED_DEVICE_MODAL);
    }
  }, [isSupported, isLoading]);

  return <StyledDiv />;
};

export default PasswordResetRedirectView;

PasswordResetRedirectView.propTypes = {
  control: ControlPropTypes,
};
