import { useEffect } from "react";

// when mounted at a subpath, redirect the user to the basepath
function BasePathRedirect() {
  const { pathname } = window.location;

  useEffect(() => {
    const basePath = process.env.PUBLIC_URL.replace(/\/$/, "");
    if (basePath && !pathname.startsWith(basePath)) {
      window.location.replace(basePath);
    }
  }, [pathname]);
  return null;
}

export default BasePathRedirect;
