import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import BirthDateForm from "components/Forms/BirthDateForm/BirthDateForm";
import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "components/RegistrationWizard/PropTypes";
import { EMAIL_PAGE_ID } from "Pages/Email/Email";
import { verifyBirthDate } from "services/api";
import { useFormikErrorFields } from "components/Hooks/useFormikErrorFields";
import { useNavigate } from "react-router-dom";

export const BIRTH_DATE_PAGE_ID = "birthDateForm";

export const BirthDateView = ({ control, formFields }) => {
  const { validateForm, setTouched, values } = useFormikContext();
  const { t } = useTranslation(),
    navigate = useNavigate(-1);

  const handleContinueButtonClick = async () => {
    const errs = await validateForm();
    if (isEmpty(errs)) {
      control.setLoading(true);
      const { valid, isRateLimited } = await verifyBirthDate(values);
      control.setLoading(false);
      if (valid) {
        control.goToStep(EMAIL_PAGE_ID);
      } else {
        if (isRateLimited) {
          control.setAlert("form.errors.rateLimitExceeded");
        } else if (valid === false) {
          control.setAlert("form.errors.invalidBirthDate");
        } else {
          control.setAlert("form.errors.general");
        }
      }
    } else {
      useFormikErrorFields(errs, setTouched);
    }
  };

  return (
    <Container control={control}>
      <BirthDateForm formFields={formFields} control={control} />
      <BlueButton
        onClick={handleContinueButtonClick}
        fullWidth={true}
        label={t("form.continueButton.label")}
        ariaLabel={t("form.continueButton.label")}
        testId="submit-birth-date-button"
        control={control}
      />
      <WhiteButton
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={() => navigate(-1)}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-button"
        control={control}
      ></WhiteButton>
    </Container>
  );
};

BirthDateView.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};
