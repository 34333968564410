import registrationFormModel from "components/Forms/model";
const {
  formFields: {
    policyNumber,
    divisionNumber,
    certNumber,
    birthDate,
    pmwUsername,
    pmwPassword,
    email,
    confirmEmail,
    isPmwUser,
    useExistingPMWEmail,
    memberSummaryEmail,
    pmwUserBirthDate,
    pmwEmail,
  },
} = registrationFormModel;

export default {
  [policyNumber.name]: "",
  [divisionNumber.name]: "",
  [certNumber.name]: "",
  [birthDate.name]: "",
  [pmwUsername.name]: "",
  [pmwPassword.name]: "",
  [pmwEmail.name]: "",
  [email.name]: "",
  [confirmEmail.name]: "",
  [isPmwUser.name]: "",
  [useExistingPMWEmail.name]: "",
  [memberSummaryEmail.name]: "",
  [pmwUserBirthDate.name]: "",
};
