import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { GreyStyledBody, StyledContainerExistingUser } from "../Common.styles";
import { Title } from "components/TextComponents/TextComponents";
import { useEffect } from "react";
import { clearFormValues } from "components/Utils/InMemoryDataStore/InMemoryDataStore";

export const THANK_YOU_SCREEN_PASSWORD_RESET_PAGE_ID =
  "thankYouScreenPasswordReset";

export const ThankYouScreenPasswordReset = ({ control }) => {
  const { t } = useTranslation();

  useEffect(() => {
    clearFormValues();
  }, []);

  return (
    <Container hideTitle hideSubtitle control={control}>
      <StyledContainerExistingUser>
        <Title title={t("thankyouScreen.resetPassword.title")} />
        <GreyStyledBody>
          {t("thankyouScreen.resetPassword.body")}
        </GreyStyledBody>
      </StyledContainerExistingUser>
    </Container>
  );
};

ThankYouScreenPasswordReset.propTypes = {
  control: ControlPropTypes,
};

export default ThankYouScreenPasswordReset;
