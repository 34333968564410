import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { HelpIcon, ColorPalette } from "empire-ui";
import SampleBenefitCardEnglish from "../../assets/img/sample_benefit_card.png";
import SampleBenefitCardFrench from "../../assets/img/sample_benefit_card_french.png";
import { Trans, useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import {
  StyledBody,
  StyledH6,
  StyledHR,
  StyledTopDiv,
} from "./SampleBenefitCard.styles";
import { MEMBER_SUMMARY_PAGE_ID } from "Pages/MemberSummary/MemberSummary";
import { useNavigate } from "react-router-dom";

export const SAMPLE_BENEFIT_CARD_PAGE_ID = "sampleBenefitCard";

export const SampleBenefitCardView = ({ control }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const customTranslationComponents = {
    bold: <b style={{ fontFamily: "MuseoSans700Regular" }} />,
    break: <br />,
    linebreak: (
      <>
        <br />
        <br />
      </>
    ),
  };

  return (
    <Container hideIcon hideSubtitle hideTitle control={control}>
      <StyledTopDiv>
        <HelpIcon
          height="33"
          width="33"
          fill={ColorPalette.secondaryDarkBlue[0]}
        />
        <StyledH6 data-testid="sample-benefit-card-information-header">
          {t("sampleBenefitCardInformation.header")}
        </StyledH6>
      </StyledTopDiv>
      <StyledHR />
      <StyledBody data-testid="sample-benefit-card-information-body">
        {t("sampleBenefitCardInformation.body.firstLine")}
      </StyledBody>
      <StyledBody>
        <Trans
          i18nKey="sampleBenefitCardInformation.body.secondLine"
          components={customTranslationComponents}
        />
      </StyledBody>
      <div style={{ alignItems: "center" }}>
        <img
          src={
            i18n.language === "fr"
              ? SampleBenefitCardFrench
              : SampleBenefitCardEnglish
          }
          alt={t("images.sampleBenefitCard.altText")}
          width="100%"
          height="169px"
        />
      </div>

      <BlueButton
        fullWidth={true}
        label={t("sampleBenefitCardInformation.emailButton")}
        onClick={() => {
          control.goToStep(MEMBER_SUMMARY_PAGE_ID);
        }}
        ariaLabel={t("sampleBenefitCardInformation.emailButton")}
        testId="get-started-button"
      ></BlueButton>

      <WhiteButton
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={() => navigate(-1)}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-to-the-previous-page-button"
      ></WhiteButton>
    </Container>
  );
};

SampleBenefitCardView.propTypes = {
  control: ControlPropTypes,
};
