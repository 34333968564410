import { useField, useFormikContext } from "formik";
import { Radio } from "empire-ui";
import PropTypes from "prop-types";

const RadioButton = (props) => {
  const [field] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <Radio
      {...props}
      onChange={() => {
        props.onChange(setFieldValue, field.name, props.value);
      }}
    />
  );
};

RadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButton;
