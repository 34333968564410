import { useAuth0 } from "@auth0/auth0-react";
import { INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID } from "components/Modals/InactivityTimeoutConfirmation/Modal";
import { useEffect } from "react";
import { isInactivityDetected } from "../Utils/InMemoryDataStore/InMemoryDataStore";

export function useInactivityTimer(control) {
  const { isLoading } = useAuth0();
  useEffect(() => {
    if (isInactivityDetected() === true && isLoading === false) {
      control.showModal(INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID);
    }
  }, [isLoading, isInactivityDetected()]);
}
