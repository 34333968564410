import { ColorPalette } from "empire-ui";
import styled from "styled-components";
import PropTypes from "prop-types";

const SvgIconStyle = styled.svg`
  color: ${ColorPalette.secondaryDarkBlue[0]};
  fill: currentColor;
`;

const SvgIcon = ({ children, width = "1.5em", height = "1.5em", ...props }) => (
  <SvgIconStyle style={{ width, height }} viewBox="0 0 24 24" {...props}>
    {children}
  </SvgIconStyle>
);

SvgIcon.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SvgIcon;
