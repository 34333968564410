import React from "react";
import PropTypes from "prop-types";
import EmpireLogo from "../../assets/img/encompassed_empire_logo.png";
import {
  StyledContainer,
  StyledImage,
  StyledDiv,
  TitleContainer,
  StyledAlert,
} from "./Container.styles";
import { Subtitle, Title } from "components/TextComponents/TextComponents";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { MaterialIcon } from "empire-ui";
import LoadingOverlay from "components/Spinner/LoadingOverlay";

const Container = ({
  title,
  hideTitle,
  subtitle,
  hideSubtitle,
  style,
  children,
  hideIcon,
  control,
}) => {
  const { t } = useTranslation(),
    isAlertPresent = () => {
      return (
        control &&
        control.alert &&
        control.alert.message &&
        control.alert.message !== "" &&
        control.alert.step === control.currentStep
      );
    };
  return (
    <StyledContainer style={style}>
      {!hideIcon && (
        <StyledImage
          src={EmpireLogo}
          alt={t("images.empireLifeIcon.altText")}
          style={{ marginTop: "-12%" }}
        />
      )}

      <TitleContainer>
        {!hideTitle && (
          <Title title={title || t("textComponents.welcome")}></Title>
        )}
        {!hideSubtitle && (
          <Subtitle subtitle={subtitle || t("textComponents.signUp")} />
        )}
      </TitleContainer>
      {isAlertPresent() && (
        <StyledAlert
          variant="error"
          icon={<MaterialIcon fontSize="34px">error</MaterialIcon>}
          data-testid="page-alert-container"
        >
          {t(control.alert.message)}
        </StyledAlert>
      )}
      <StyledDiv>
        {control && control.isLoading && <LoadingOverlay />}
        {children}
      </StyledDiv>
    </StyledContainer>
  );
};

export default Container;

Container.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  subtitle: PropTypes.string,
  hideSubtitle: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.any,
  hideIcon: PropTypes.bool,
  control: ControlPropTypes,
};
