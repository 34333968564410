import React from "react";
import PropTypes from "prop-types";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { PreExistingUserModal } from "../PreExistingUserWithEmail/Modal";

export const EMAIL_IN_USE_MODAL_ID = "email-in-use-modal";

export const EmailInUseModal = ({ open, setOpen, control }) => {
  return (
    <PreExistingUserModal
      open={open}
      setOpen={setOpen}
      control={control}
      bodytext="bodyBenefitCard"
    />
  );
};

EmailInUseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes.isRequired,
};
