import { useEffect, useMemo, useState } from "react";
import { getConfig } from "../../AppConfig";
import { getPlatformIsMobile } from "../Utils/Common";
import { getIsPortalEnabled } from "../Utils/InMemoryDataStore/InMemoryDataStore";

export const useIsPlatformSupported = () => {
  const [isDesktopSupported, setIsDesktopSupported] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useMemo(() => {
    return getPlatformIsMobile();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { isPortalSupported } = await getConfig();
      const isPortalActive = getIsPortalEnabled();
      setIsDesktopSupported(isPortalSupported && isPortalActive);
      setIsLoading(false);
    })();
  }, [setIsDesktopSupported, setIsLoading]);

  return { isSupported: isMobile || isDesktopSupported, isLoading };
};
