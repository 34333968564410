import { LINK_EXPIRED_PAGE_ID } from "Pages/LinkExpired/LinkExpired";
import { THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID } from "Pages/ThankYouScreen/ExistingUser";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useQuery from "./useQuery";

// Handles redirecting the user to proper screens following an initial password reset
export function useAuth0RedirectHandler(control) {
  const query = useQuery();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/" &&
      query.has("email") &&
      query.has("success")
    ) {
      const auth0Email = query.get("email");
      const status = query.get("success").toLowerCase() === "true";

      // Bad code smell - Redirecting too fast as a child of the router causes issues, slowing it down a bit
      setTimeout(() => {
        if (status === false) {
          control.goToStep(LINK_EXPIRED_PAGE_ID, {
            state: { email: auth0Email },
            replace: true,
          });
        } else {
          control.goToStep(THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID, {
            replace: true,
          });
        }
      }, 0);
    }
  }, [query]);
}
