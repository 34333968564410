import { useConfig } from "AppConfig";
import { useTranslation } from "react-i18next";

export const useLaunchDate = () => {
  const { launchDate } = useConfig() || {};
  const { i18n } = useTranslation();
  let result = "";
  if (launchDate) {
    result = new Intl.DateTimeFormat(i18n.language, {
      dateStyle: "long",
    }).format(new Date(launchDate));
  }
  return result;
};
