import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import ResponsiveModal from "../ResponsiveModal";
import { TERMS_AND_CONDITIONS_PAGE_ID } from "Pages/TermsAndConditions/TermsAndConditions";

export const EMAIL_CONFIRMATION_MODAL_ID = "email-confirmation-modal";

export const EmailConfirmationModal = ({ open, setOpen, control }) => {
  const { t } = useTranslation();
  const onClick = () => {
    setOpen(false);
    control.goToStep(TERMS_AND_CONDITIONS_PAGE_ID);
  };
  return (
    <ResponsiveModal
      testId="email-confirmation-modal"
      setOpen={setOpen}
      open={open}
      title={t("emailConfirmationModal.title")}
      description={t("emailConfirmationModal.body")}
      actions={[
        {
          label: t("emailConfirmationModal.buttonText"),
          testId: "continue-button",
          onClick: onClick,
        },
      ]}
    />
  );
};

EmailConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes,
};
