import initialValues from "components/Forms/initialValues";

export const formValueKey = "formFields",
  currentAlertKey = "currentAlert",
  inactivityDetectectionKey = "isInactivityDetected",
  contextKey = "isPortal";

export const getFormValues = () => {
  const formValues =
    sessionStorage.getItem(formValueKey) === null
      ? initialValues
      : JSON.parse(sessionStorage.getItem(formValueKey));
  return formValues;
};

export const setFormValues = (formValues) => {
  saveTosession(formValueKey, formValues);
};

export const getCurrentAlert = () => {
  const currentAlert =
    sessionStorage.getItem(currentAlertKey) === null
      ? { step: "", message: "" }
      : JSON.parse(sessionStorage.getItem(currentAlertKey));
  return currentAlert;
};

export const setAlert = (alert) => {
  saveTosession(currentAlertKey, alert);
};

export const isInactivityDetected = () => {
  return sessionStorage.getItem(inactivityDetectectionKey) === null
    ? false
    : JSON.parse(sessionStorage.getItem(inactivityDetectectionKey));
};

export const setInactivityDetected = (value) => {
  saveTosession(inactivityDetectectionKey, value);
};

export const clearFormValues = () => {
  sessionStorage.removeItem(formValueKey);
};

const saveTosession = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getIsPortalEnabled = () => {
  let isPortalEnabled;
  const search = new URLSearchParams(window.location.search);
  if (search.has(contextKey)) {
    isPortalEnabled = search.get(contextKey) === "true";
    setIsPortalEnabled(isPortalEnabled);
  } else if (sessionStorage.getItem(contextKey) === null) {
    isPortalEnabled = false;
    setIsPortalEnabled(isPortalEnabled);
  } else {
    isPortalEnabled = JSON.parse(sessionStorage.getItem(contextKey));
  }
  return isPortalEnabled;
};

export const setIsPortalEnabled = (flagValue) => {
  saveTosession(contextKey, flagValue);
};
