import { useEffect, useState } from "react";

export default function useIsMobile(breakpoint = 768) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenSize(window.innerWidth);
      });
    };
  }, [setScreenSize]);

  return screenSize <= breakpoint;
}
