import styled from "styled-components";
import {
  Button,
  ColorPalette,
  DialogHeader,
  MaterialIcon,
  Typography,
} from "empire-ui";
import { Link } from "react-router-dom";

const fontStylingConstants = Typography.Constants;

export const StyledHeader = styled(DialogHeader)`
  color: ${ColorPalette.secondaryLightBlue[2]};
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseIcon = styled(MaterialIcon)`
  color: ${ColorPalette.darkBlue[0]};
  width: 15px;
  height: 15px;
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 3%;
  top: 8%;
  cursor: pointer;
`;

export const StyledTitle = styled.b`
  color: ${ColorPalette.darkBlue[0]};
  font-size: 16px;
  line-height: 20px;
`;

export const StyledBoldText = styled.b`
  font-size: 16px;
  color: ${ColorPalette.grey[0]};
  margin-left: 10px;
  line-height: 20px;
  margin-top: -1%;
`;

export const StyledText = styled.text`
  font-size: 14px;
  color: ${ColorPalette.grey[2]};
  line-height: 20px;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
  display: flex;
  margin-left: 25px;
  text-align: left;
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  color: ${ColorPalette.secondaryDarkBlue[0]};
  display: flex;
  margin-left: 25px;
  text-align: left;
`;

export const IconAndHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
