import styled from "styled-components";
import { ColorPalette } from "empire-ui";

export const StyledTopDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
`;

export const StyledBody = styled.p`
  font-size: 16px;
  text-align: left;
  color: #555555;
`;

export const StyledH6 = styled.h6`
  font-size: 18px;
  text-align: left;
  padding-left: 25px;
`;

export const StyledHR = styled.hr`
  width: 122%;
  margin-left: -11%;
  color: ${ColorPalette.grey[8]};
`;
