import styled from "styled-components";
import { ColorPalette, Typography } from "empire-ui";

const fontStylingConstants = Typography.Constants;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InformationContainer = styled(VerticalDiv)`
  margin-left: 26px;
`;

export const StepperContainer = styled(VerticalDiv)`
  width: 40px;
`;

export const MainContainer = styled.div`
  align-items: left;
  margin-bottom: 30px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MarginedHorizontalContainer = styled(HorizontalContainer)`
  margin-top: 20px;
`;

export const Circle = styled.div`
  width: 41.24px;
  height: 39.59px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 14px;
  color: ${ColorPalette.darkBlue[0]};
  text-align: center;
  box-sizing: border-box;
  border: 1px solid ${ColorPalette.green[0]};
`;

const VerticalLine = styled.div`
  width: 3px;
  background: #c9c9c5;
  margin-left: 45%;
`;

export const LongVerticalLine = styled(VerticalLine)`
  height: 100%;
  margin-top: 5px;
`;

export const ShortVerticalLine = styled(VerticalLine)`
  height: 20px;
  margin-bottom: 5px;
`;

export const StyledContentTitle = styled.h5`
  color: ${ColorPalette.grey[0]};
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: 14px;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
  text-align: left;
`;

export const StyledSubTitle = styled(StyledContentTitle)`
  margin-top: 30px;
  font-size: 16px;
`;

export const StyledBody = styled.p`
  margin-top: 5px;
  font-size: 14px;
  text-align: left;
  color: #555555;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
`;
