import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { EMAIL_PAGE_ID } from "Pages/Email/Email";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import ResponsiveModal from "../ResponsiveModal";

export const INCORRECT_EMAIL_MODAL_ID = "incorrect-email-modal";

export const IncorrectEmailModal = ({ open, setOpen, control }) => {
  const { t } = useTranslation();
  const onClick = () => {
    setOpen(false);
    control.goToStep(EMAIL_PAGE_ID);
  };
  return (
    <ResponsiveModal
      testId="incorrect-email-modal"
      setOpen={setOpen}
      open={open}
      title={t("incorrectEmailModal.title")}
      description={t("incorrectEmailModal.body")}
      actions={[
        {
          label: t("incorrectEmailModal.button.label"),
          testId: "continue-button",
          onClick: onClick,
        },
      ]}
    />
  );
};

IncorrectEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes,
};
