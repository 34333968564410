import styled from "styled-components";
import { StyledForm, StyledLabel } from "../Form.styles";

/**
 * This complicated piece of .CSS is to handle overriding the styles associated with the
 * empire-ui DateInput component. By default this component has static width attributes and
 * those need to be overridden to support full-width inputs.
 */
export const StyledBirthDateForm = styled(StyledForm)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > div > div:nth-child(1) {
    flex: 2;
    padding-right: 10px;
  }
  & > div > div:nth-child(2) {
    flex: 2;
    padding-right: 10px;
  }
  & > div > div:nth-child(3) {
    flex: 4;
  }
`;

export const StyledBirthDateLabel = styled(StyledLabel)`
  width: 100%;
`;
