import React from "react";
import PropTypes from "prop-types";
import { CallIcon, ClockIcon, ColorPalette, Dialog, MailIcon } from "empire-ui";
import { useTranslation } from "react-i18next";

import {
  StyledHeader,
  StyledTitle,
  StyledBoldText,
  StyledText,
  IconAndHeadingContainer,
  StyledCloseButton,
  StyledCloseIcon,
  StyledLink,
} from "./Modal.styles";
import { StyledDialogBody } from "../Modal.styles";

export const HELP_MODAL_ID = "help-modal";

export const HelpModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      data-testid="help-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="360px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledHeader>
        <StyledTitle id="modal-title">{t("helpModal.header")}</StyledTitle>
        <StyledCloseButton
          fab={true}
          style={{ height: "0px", width: "0px" }}
          onClick={() => setOpen(false)}
          data-testid="close-button"
        >
          <StyledCloseIcon>remove</StyledCloseIcon>
        </StyledCloseButton>
      </StyledHeader>
      <StyledDialogBody id="modal-description">
        <IconAndHeadingContainer>
          <ClockIcon height="15" width="15" fill={ColorPalette.darkBlue[0]} />
          <StyledBoldText>{t("helpModal.hours.title")}</StyledBoldText>
        </IconAndHeadingContainer>
        <StyledText>{t("helpModal.hours.body")}</StyledText>
        <IconAndHeadingContainer style={{ marginTop: "16px" }}>
          <CallIcon height="15" width="15" fill={ColorPalette.darkBlue[0]} />
          <StyledBoldText>{t("helpModal.phone.title")}</StyledBoldText>
        </IconAndHeadingContainer>
        <StyledText>{t("helpModal.phone.body")}</StyledText>
        <IconAndHeadingContainer style={{ marginTop: "16px" }}>
          <MailIcon height="15" width="15" fill={ColorPalette.darkBlue[0]} />
          <StyledBoldText>{t("helpModal.email.title")}</StyledBoldText>
        </IconAndHeadingContainer>
        <StyledLink
          onClick={() => (window.location = "mailto:yourmail@domain.com")}
        >
          {t("helpModal.email.body")}
        </StyledLink>
      </StyledDialogBody>
    </Dialog>
  );
};

HelpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
