import React from "react";
import SvgIcon from "./SvgIcon";

const ChevronRightIcon = () => {
  return (
    <SvgIcon data-testid="chevron-right-icon">
      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </SvgIcon>
  );
};

export default ChevronRightIcon;
