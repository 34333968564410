import { useState } from "react";
import PropTypes from "prop-types";
import RadioButton from "./RadioButton";

const RadioGroup = ({ options, fieldName, defaultSelection }) => {
  const [radioOption, setOption] = useState(defaultSelection);
  const onChange = (setFieldValue, name, value) => {
    setOption(value);
    setFieldValue(name, value);
  };
  return (
    <div style={{ display: "flex", marginTop: "10px" }}>
      {options.map((option, index) => (
        <RadioButton
          key={index}
          label={option.label}
          value={option.value}
          name={fieldName}
          selection={radioOption}
          onChange={onChange}
          fatLabel
        />
      ))}
    </div>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  fieldName: PropTypes.string.isRequired,
  defaultSelection: PropTypes.string.isRequired,
};
