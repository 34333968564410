import { Dialog } from "empire-ui";
import {
  ActionLink,
  DialogueBody,
  StyledDialogBody,
  StyledImage,
  StyledTitle,
} from "./Modal.styles";
import React from "react";
import { useTranslation } from "react-i18next";
import modalIcon from "../../assets/img/compass.png";
import useIsMobile from "../../hooks/useIsMobile";
import PropTypes from "prop-types";
import ChevronRightIcon from "../Icons/ChevronRightIcon";

const ResponsiveModal = ({
  open,
  setOpen,
  title,
  description,
  showCloseButton = false,
  actions = [],
  testId,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  let newActions = actions;
  if (showCloseButton) {
    newActions = [
      ...actions,
      {
        label: t("buttons.close.label"),
        testId: "close-modal-button",
        onClick: () => setOpen(false),
      },
    ];
  }
  const props = {
    testId,
    open,
    setOpen,
    title,
    description,
    actions: newActions,
  };
  return isMobile ? <MobileModal {...props} /> : <DesktopModal {...props} />;
};

const DesktopModal = ({
  testId,
  open,
  setOpen,
  title,
  description,
  actions,
}) => {
  return (
    <Dialog
      data-testid={testId}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="38rem"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <div style={{ padding: "2.5rem", paddingTop: "calc(2.5rem + 12.5px)" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "flex-start",
          }}
        >
          <div style={{ paddingRight: "1rem" }}>
            <StyledImage
              style={{ marginTop: "-12.5px", width: "40px", height: "40px" }}
              src={modalIcon}
              alt=""
            />
          </div>
          <div style={{ textAlign: "left" }}>
            <StyledTitle id="modal-title">{title}</StyledTitle>
            {typeof description === "string" && (
              <DialogueBody id="modal-description">{description}</DialogueBody>
            )}
            {typeof description !== "string" && (
              <div id={"modal-description"}>{description}</div>
            )}
            <DialogActions actions={actions} isMobile={false} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const MobileModal = ({
  testId,
  open,
  setOpen,
  title,
  description,
  actions,
}) => {
  return (
    <Dialog
      data-testid={testId}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">{title}</StyledTitle>
        {typeof description === "string" && (
          <DialogueBody id="modal-description">{description}</DialogueBody>
        )}
        {typeof description !== "string" && (
          <div id={"modal-description"}>{description}</div>
        )}
        <DialogActions actions={actions} isMobile={true} />
      </StyledDialogBody>
    </Dialog>
  );
};

const DialogActions = ({ actions, isMobile }) => {
  return (
    actions &&
    actions.length > 0 && (
      <div>
        {actions.map(({ label, onClick, testId, disabled }, i) => (
          <div
            key={i}
            onClick={disabled === true ? () => {} : onClick}
            style={{
              paddingTop: "1rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "flex-start",
              cursor: "pointer",
            }}
          >
            <ActionLink disabled={disabled} data-testid={testId} to="#">
              {label}
            </ActionLink>
            {!disabled && <ChevronRightIcon />}
          </div>
        ))}
      </div>
    )
  );
};

ResponsiveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  showCloseButton: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      testId: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
};

DialogActions.propTypes = {
  actions: ResponsiveModal.propTypes?.actions,
  isMobile: PropTypes.bool.isRequired,
};
MobileModal.propTypes = ResponsiveModal.propTypes;
DesktopModal.propTypes = ResponsiveModal.propTypes;

export default ResponsiveModal;
