import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useIdleTimerContext } from "react-idle-timer";
import ResponsiveModal from "../ResponsiveModal";

export const INACTIVITY_TIMEOUT_WARNING_MODAL_ID = "inactivity-timeout-warning";

export const InactivityTimeoutWarningModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const idleTimer = useIdleTimerContext();
  return (
    <ResponsiveModal
      testId="inactivity-warning-modal"
      setOpen={setOpen}
      open={open}
      title={t("inactivityTimeout.warningModal.title")}
      description={t("inactivityTimeout.warningModal.body")}
      actions={[
        {
          label: t("inactivityTimeout.warningModal.buttonLabel"),
          testId: "acknowledge-user-is-active-button",
          onClick: () => {
            setOpen(false);
            idleTimer.reset();
          },
        },
      ]}
    />
  );
};

InactivityTimeoutWarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
