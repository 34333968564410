import { BlueButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "components/RegistrationWizard/PropTypes";
import {
  Circle,
  MainContainer,
  StyledBody,
  MarginedHorizontalContainer,
  HorizontalContainer,
  StepperContainer,
  InformationContainer,
  LongVerticalLine,
  ShortVerticalLine,
  StyledSubTitle,
  StyledContentTitle,
} from "./Landing.styles";
import { IS_PMW_USER_PAGE_ID } from "Pages/PMWUser/isPMWUser";
export const LANDING_PAGE_ID = "landingPage";

export const LandingView = ({ control }) => {
  const { t } = useTranslation();

  const handleContinueButtonClick = () => {
    control.goToStep(IS_PMW_USER_PAGE_ID);
  };

  return (
    <Container control={control}>
      <MainContainer>
        <StyledBody style={{ marginTop: "20px" }}>
          {t("landingPage.body")}
        </StyledBody>
        <StyledSubTitle>{t("landingPage.title")}</StyledSubTitle>

        <MarginedHorizontalContainer>
          <StepperContainer>
            <Circle>1</Circle>
            <LongVerticalLine />
          </StepperContainer>
          <InformationContainer>
            <StyledContentTitle>
              {t("landingPage.personalDetails.title")}
            </StyledContentTitle>
            <StyledBody>{t("landingPage.personalDetails.body")}</StyledBody>
          </InformationContainer>
        </MarginedHorizontalContainer>

        <StepperContainer>
          <ShortVerticalLine />
        </StepperContainer>

        <HorizontalContainer>
          <StepperContainer>
            <Circle>2</Circle>
          </StepperContainer>
          <InformationContainer>
            <StyledContentTitle>
              {t("landingPage.loginCredentials.title")}
            </StyledContentTitle>
            <StyledBody>{t("landingPage.loginCredentials.body")}</StyledBody>
          </InformationContainer>
        </HorizontalContainer>
      </MainContainer>

      <BlueButton
        fullWidth={true}
        label={t("landingPage.getStartedButton.label")}
        onClick={handleContinueButtonClick}
        ariaLabel={t("landingPage.getStartedButton.aria-label")}
        testId="get-started-button"
      ></BlueButton>
    </Container>
  );
};

LandingView.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};
