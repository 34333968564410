import { useEffect, useState } from "react";

let config = null;

/**
 * Test usage only for clearing the config cache.
 */
export const resetConfig = () => {
  config = null;
};

export const getConfig = async () => {
  if (!config) {
    const PUBLIC_URL = process.env.PUBLIC_URL;
    const response = await fetch(PUBLIC_URL + "/config/config.json");
    config = await response.json();
  }
  return config;
};

export const useConfig = () => {
  const [configs, setConfigs] = useState(null);
  useEffect(() => {
    getConfig().then(setConfigs);
  }, []);

  return configs;
};
