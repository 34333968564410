import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import ResponsiveModal from "../ResponsiveModal";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { EMAIL_PAGE_ID } from "Pages/Email/Email";

export const PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID = "pre-existing-auth0-account";

export const PreExistingAuth0AccountModal = ({
  open,
  setOpen,
  setIsNewAccount,
  control,
}) => {
  const { t, i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const { values, setFieldValue } = useFormikContext();

  const handleContinueToLinkEmail = () => {
    setIsNewAccount(false);
    loginWithRedirect({
      login_hint: values.email,
      prompt: "login", // Forces us to log in EVERY time
      language: i18n.language,
    });
  };

  return (
    <ResponsiveModal
      testId="email-in-use-modal"
      setOpen={setOpen}
      open={open}
      title={t("preExistingAuth0AccountModal.title")}
      description={t("preExistingAuth0AccountModal.body")}
      actions={[
        {
          label: t("preExistingAuth0AccountModal.continue"),
          testId: "continue-with-my-email-button",
          onClick: handleContinueToLinkEmail,
        },
        {
          label: t("preExistingAuth0AccountModal.changeEmail"),
          testId: "use-different-email-button",
          onClick: () => {
            setOpen(false);
            setFieldValue("email", "");
            setFieldValue("confirmEmail", "");
            control.goToStep(EMAIL_PAGE_ID);
          },
        },
      ]}
    />
  );
};

PreExistingAuth0AccountModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setIsNewAccount: PropTypes.func.isRequired,
  control: ControlPropTypes,
};
