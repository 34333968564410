import { StyledForm, StyledLabel } from "../Form.styles";
import TextInput from "../../Fields/TextInput";
import { useTranslation } from "react-i18next";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "../../RegistrationWizard/PropTypes";

const PmwCredentialsForm = ({ formFields, control }) => {
  const { t } = useTranslation();
  const { pmwUsername, pmwPassword } = formFields;

  return (
    <StyledForm>
      <StyledLabel id="pmw-username">
        {t("pmwUser.credentialsValidation.email.label")}
      </StyledLabel>
      <TextInput
        name={pmwUsername.name}
        placeholder={t("pmwUser.credentialsValidation.email.placeHolder")}
        aria-labelledby="pmw-username"
        data-testid="pmw-username"
        control={control}
      />

      <StyledLabel id="pmw-password">
        {t("pmwUser.credentialsValidation.password.label")}
      </StyledLabel>
      <TextInput
        name={pmwPassword.name}
        type="password"
        placeholder={t("pmwUser.credentialsValidation.password.placeHolder")}
        aria-labelledby="pmw-password"
        data-testid="pmw-password"
        control={control}
      />
    </StyledForm>
  );
};

PmwCredentialsForm.propTypes = {
  formFields: FormFieldPropTypes,
  control: ControlPropTypes,
};

export default PmwCredentialsForm;
