import { Trans, useTranslation } from "react-i18next";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "components/RegistrationWizard/PropTypes";
import RadioGroup from "components/Fields/RadioGroup";
import { useFormikContext } from "formik";
import {
  StyledRadioGroupForm,
  StyledRadioGroupLabel,
} from "Pages/Common.styles";
import { Link } from "react-router-dom";
import EmailForm from "../EmailForm/EmailForm";
import { useEffect } from "react";

const PMWUserForm = ({ formFields }) => {
  const { t } = useTranslation();
  const { useExistingPMWEmail, pmwEmail } = formFields;
  const { values, setFieldValue } = useFormikContext();
  const customTranslationComponents = {
    email: (
      <Link onClick={() => (window.location = `mailto:${values.pmwEmail}`)} />
    ),
  };

  useEffect(() => {
    if (values.useExistingPMWEmail === "true") {
      setFieldValue("email", values.pmwEmail);
      setFieldValue("confirmEmail", values.pmwEmail);
    } else {
      setFieldValue("email", "");
      setFieldValue("confirmEmail", "");
    }
  }, [values.useExistingPMWEmail, pmwEmail, setFieldValue]);

  return (
    <StyledRadioGroupForm>
      <StyledRadioGroupLabel data-testid="existing-pmw-email-label">
        <Trans
          i18nKey={"pmwUser.validUser.email.pmwEmail.body.firstLine"}
          components={customTranslationComponents}
          values={{ emailValue: values.pmwEmail }}
        />
      </StyledRadioGroupLabel>
      <RadioGroup
        options={[
          { label: t("pmwUser.answers.yes"), value: "true" },
          { label: t("pmwUser.answers.no"), value: "false" },
        ]}
        fieldName={useExistingPMWEmail.name}
        defaultSelection={values.useExistingPMWEmail}
      />
      {values.useExistingPMWEmail === "false" && (
        <EmailForm
          formField={formFields}
          firstLabel="form.email.newEmailLabel"
          secondLabel="form.email.confirmNewEmailLabel"
        />
      )}
      <StyledRadioGroupLabel data-testid="existing-pmw-email-label-2">
        {t("pmwUser.validUser.email.pmwEmail.body.secondLine")}
      </StyledRadioGroupLabel>
    </StyledRadioGroupForm>
  );
};

export default PMWUserForm;

PMWUserForm.propTypes = {
  formFields: FormFieldPropTypes,
  control: ControlPropTypes,
};
