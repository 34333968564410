import styled from "styled-components";
import { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ValidRoutes } from "../Routes/Routes";
import registrationFormModel from "components/Forms/model";
import validationSchema from "components/Forms/validationSchema";
import { useLocation, useNavigate } from "react-router-dom";
import { LANDING_PAGE_ID } from "Pages/Landing/Landing";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  clearFormValues,
  getCurrentAlert,
  getFormValues,
  setAlert,
  setInactivityDetected,
} from "components/Utils/InMemoryDataStore/InMemoryDataStore";
import { INACTIVITY_TIMEOUT_WARNING_MODAL_ID } from "components/Modals/InactivityTimeoutWarning/Modal";
import { redirectToLogin } from "components/Utils/Common";
import { IdleTimerProvider } from "react-idle-timer";
import { LOADING_VIEW_PAGE_ID } from "Pages/Loading/LoadingView";
import { TERMS_AND_CONDITIONS_PAGE_ID } from "Pages/TermsAndConditions/TermsAndConditions";
import { useConfig } from "AppConfig";
import RegistrationModals from "./RegistrationModals";

const { formId, formFields } = registrationFormModel;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
`;

const RegistrationWizard = () => {
  const [currentStep, setCurrentStep] = useState(LANDING_PAGE_ID);
  const [activeModal, setActiveModal] = useState("");
  const [currentAlert, setCurrentAlert] = useState(getCurrentAlert());
  const [isLoading, setIsLoading] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(undefined);
  const currentValidationSchema = validationSchema[currentStep];
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const {
    auth0Domain,
    auth0ClientId,
    inactivityTimeout,
    inactivityPromptDuration,
  } = useConfig() || {};

  useEffect(() => {
    const currentStep = pathname.substring(1);
    setCurrentStep(currentStep);
  }, [pathname]);

  useEffect(() => {
    setAlert(currentAlert);
  }, [currentAlert]);

  const navigate = useNavigate();
  const control = {
    alert: currentAlert,
    isLoading,
    isNewAccount: isNewAccount,
    currentStep: currentStep,
    goToStep: (step, { state = {}, replace = false } = {}) => {
      navigate(`/${step}`, {
        state: { isValidNavigation: true, ...state },
        replace: replace,
      });
    },
    showModal: (modalId) => {
      setActiveModal(modalId);
    },
    setAlert: (message) => {
      setCurrentAlert({ step: currentStep, message: message });
    },
    setLoading: (loadingState) => {
      if (loadingState === true) {
        setCurrentAlert({ step: "", message: "" });
      }
      setIsLoading(loadingState);
    },
    setIsNewAccount,
    redirectToAppLogin: () => {
      redirectToLogin(i18n.language);
    },
    redirectToPortalLogin: () => {
      redirectToLogin(i18n.language);
    },
  };

  const handleSubmit = (values, actions) => {
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const onRedirectCallback = () => {
    navigate(`/${TERMS_AND_CONDITIONS_PAGE_ID}`, {
      state: { isValidNavigation: true },
      replace: true,
    });
  };

  const onIdle = () => {
    setInactivityDetected(true);
    clearFormValues();
    window.location.assign(
      `https://${auth0Domain}/v2/logout?client_id=${auth0ClientId}&returnTo=${window.location.origin}${process.env.PUBLIC_URL}`
    );
  };

  if (auth0Domain) {
    return (
      <IdleTimerProvider
        timeout={Number(inactivityTimeout)}
        promptTimeout={Number(inactivityPromptDuration)}
        onPrompt={() => {
          setActiveModal(INACTIVITY_TIMEOUT_WARNING_MODAL_ID);
        }}
        onIdle={onIdle}
      >
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          redirectUri={`${window.location.origin}${process.env.PUBLIC_URL}/${LOADING_VIEW_PAGE_ID}`}
          onRedirectCallback={onRedirectCallback}
        >
          <Formik
            validationSchema={currentValidationSchema}
            initialValues={getFormValues()}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <StyledForm id={formId}>
              <ValidRoutes control={control} formFields={formFields} />
              <RegistrationModals
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                setIsNewAccount={setIsNewAccount}
                control={control}
              />
            </StyledForm>
          </Formik>
        </Auth0Provider>
      </IdleTimerProvider>
    );
  }
};

export default RegistrationWizard;
