import PropTypes from "prop-types";
import { Button } from "empire-ui";
import {
  StyledHeader,
  LogoContainer,
  TitleContainer,
  StyledAppTitle,
  LangContainer,
  LangSeparator,
} from "./Header.styles";

import EmpireLogo from "../../assets/img/temp_logo.png";

const HeaderBar = ({ i18n, t, onToggleLanguage }) => {
  const langToDisplay = (i18n.language === "fr" ? "en" : "fr").toUpperCase();
  const appTitle = t("header.app-title");

  return (
    <StyledHeader>
      <LogoContainer>
        <img
          src={EmpireLogo}
          alt={t("images.empireLifeIcon.altText")}
          width="43px"
          height="43px"
        />
      </LogoContainer>

      <TitleContainer>
        <StyledAppTitle>{appTitle}</StyledAppTitle>
      </TitleContainer>

      <LangContainer>
        <LangSeparator />
        <Button
          onClick={onToggleLanguage}
          variant="text"
          size="small"
          data-testid="btn-language-toggle"
          aria-label={t("header.language-toggle")}
        >
          {langToDisplay}
        </Button>
      </LangContainer>
    </StyledHeader>
  );
};

HeaderBar.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
  t: PropTypes.func.isRequired,
  onToggleLanguage: PropTypes.func.isRequired,
};

export default HeaderBar;
