import ResponsiveModal from "../ResponsiveModal";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ControlPropTypes } from "../../RegistrationWizard/PropTypes";
import { BENEFIT_CARD_PAGE_ID } from "../../../Pages/BenefitCard/BenefitCard";
import { MEMBER_SUMMARY_PAGE_ID } from "../../../Pages/MemberSummary/MemberSummary";
import { useFormikContext } from "formik";

export const FORGOT_CREDENTIALS_MODAL_ID = "forgot-credentials-modal";

const ForgotCredentialsModal = ({ open, setOpen, control }) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  return (
    <ResponsiveModal
      testId="forgot-credentials-modal"
      setOpen={setOpen}
      open={open}
      title={t("pmwUser.credentialsValidation.forgotCredModal.title")}
      description={t("pmwUser.credentialsValidation.forgotCredModal.body")}
      actions={[
        {
          label: t("pmwUser.credentialsValidation.forgotCredModal.buttons.yes"),
          onClick: () => {
            control.goToStep(BENEFIT_CARD_PAGE_ID);
            setOpen(false);
            setFieldValue("isPmwUser", "false");
          },
          testId: "have-information",
        },
        {
          label: t("pmwUser.credentialsValidation.forgotCredModal.buttons.no"),
          onClick: () => {
            control.goToStep(MEMBER_SUMMARY_PAGE_ID);
            setOpen(false);
          },
          testId: "do-not-have-information",
        },
      ]}
    />
  );
};

ForgotCredentialsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes,
};

export default ForgotCredentialsModal;
