import React from "react";
import PropTypes from "prop-types";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { PreExistingUserModal } from "../PreExistingUserWithEmail/Modal";

export const PRE_EXISTING_USER_WITHOUT_EMAIL_MODAL_ID =
  "existing-user-without-email";

export const ExistingUserWithoutEmail = ({ open, setOpen, control }) => {
  return (
    <PreExistingUserModal
      open={open}
      setOpen={setOpen}
      control={control}
      bodytext="bodyBenefitCard"
      displayResetPassword={false}
    />
  );
};

ExistingUserWithoutEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes.isRequired,
};
