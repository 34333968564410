import styled from "styled-components";
import { Button, Typography, ColorPalette } from "empire-ui";

const fontStylingConstants = Typography.Constants;

export const StyledHelpButton = styled(Button)`
  background: #ffffff;
  border: none;
  bottom: 10px;
  right: 10px;
  position: absolute;
  border-radius: 30px;
  height: 30px;
  width: 75px;
`;

export const StyledImage = styled.img`
  cursor: pointer;
  width: 17px;
  height: 14px;
`;

export const StyledBlueButton = styled(Button)`
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: ${fontStylingConstants.fontSizeLevel[4]}px;
  letter-spacing: ${fontStylingConstants.letterSpacingWide}px;
  background-color: ${ColorPalette.darkBlue[0]};
`;

export const StyledWhiteButton = styled(Button)`
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: ${fontStylingConstants.fontSizeLevel[4]}px;
  letter-spacing: ${fontStylingConstants.letterSpacingWide}px;
  background-color: white;
  color: ${ColorPalette.darkBlue[0]};
  border-color: ${ColorPalette.darkBlue[0]};
`;

export const StyledHelpDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledHelpLabel = styled.div`
  padding-left: 5px;
  color: ${ColorPalette.darkBlue[0]};
  font-size: 14px;
  font-weight: strong;
  letter-spacing: 0px;
`;
