import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { setInactivityDetected } from "components/Utils/InMemoryDataStore/InMemoryDataStore";
import { useIdleTimerContext } from "react-idle-timer";
import ResponsiveModal from "../ResponsiveModal";

export const INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID =
  "inactivity-timeout-confirmation";

export const InactivityTimeoutConfirmationModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const idleTimer = useIdleTimerContext();

  useEffect(() => {
    if (open) {
      idleTimer.pause();
    }
  }, [open]);

  const handleContinue = () => {
    setOpen(false);
    setInactivityDetected(false);
    idleTimer.reset();
    idleTimer.resume();
  };

  return (
    <ResponsiveModal
      testId="inactivity-confirmation-modal"
      setOpen={setOpen}
      open={open}
      title={t("inactivityTimeout.confirmationModal.title")}
      description={t("inactivityTimeout.confirmationModal.body")}
      actions={[
        {
          label: t("inactivityTimeout.confirmationModal.buttonLabel"),
          testId: "continue-button",
          onClick: handleContinue,
        },
      ]}
    />
  );
};

InactivityTimeoutConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
