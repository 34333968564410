import React from "react";
import HelpIcon from "../../assets/img/helpIcon.png";
import {
  StyledHelpButton,
  StyledImage,
  StyledHelpDiv,
  StyledHelpLabel,
} from "./Button.styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const HelpButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledHelpButton
      onClick={() => onClick(true)}
      type="submit"
      data-testid="help-button"
      ariaLabel={t("buttons.help.ariaLabel")}
    >
      <StyledHelpDiv>
        <StyledImage src={HelpIcon} alt={t("images.helpIcon.altText")} />
        <StyledHelpLabel data-testid="help-button-label">
          {t("buttons.help.label")}
        </StyledHelpLabel>
      </StyledHelpDiv>
    </StyledHelpButton>
  );
};

export default HelpButton;

HelpButton.propTypes = {
  onClick: PropTypes.func,
};
