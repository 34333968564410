import { Typography } from "empire-ui";
import styled from "styled-components";

const fontStylingConstants = Typography.Constants;

export const StyledForm = styled.div`
  width: 100%;
  text-align: left;
  width: 100%;
  margin-top: 12px;
  max-height: auto;
  margin-bottom: 10px;
`;

export const StyledLabel = styled.label`
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: 16px;
  display: block;
  margin-top: 18px;
  margin-bottom: 8px;
  color: #555555;
`;
